import React, { useState, useEffect } from 'react';
import { getTimeNotData } from '../utility';
import CallToAction from '../cta';

const TimeNot = () => {
  const [timeNotContent, setTimeNotContent] = useState({});

  useEffect(() => {
    const data = getTimeNotData();
    if (data) {
      setTimeNotContent(data);
    }
  }, []);

  return (
    <div className="bg-blue-50 mx-auto flex flex-col md:flex-row items-center justify-center md:py-12 md:px-32">
      {/* Left Column */}
      <div className="md:w-1/2 w-11/12 flex items-center justify-center md:pr-8">
        <img
          className="max-w-full max-h-full"
          src={timeNotContent.image}
          alt="Responsive Image"
        />
      </div>

      {/* Right Column */}
      <div className="md:w-1/2 w-11/12 p-4 md:py-0 py-12">
        <h2 className="text-3xl font-bold mb-2 text-blue-700 text-left mb-8">{timeNotContent.title}</h2>
        <p className="mb-8 text-slate-800 font-semibold text-left">
          {timeNotContent.description}
        </p>
        <div className='flex space-y-4 justify-center'>
        {timeNotContent.buttons &&
            timeNotContent.buttons.map((button, index) => (
              <CallToAction key={index} link={button.link} text={button.text} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default TimeNot;