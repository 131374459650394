import React, { useState, useEffect } from 'react';
import CallToAction from '../cta';
import { getHeroData } from '../utility';

const HeroLanding = () => {
  const [heroContent, setHeroContent] = useState({});

  useEffect(() => {
    const data = getHeroData();
    setHeroContent(data);
  }, []);

  return (
    <div id='top' className="bg-blue-900 h-screen flex px-32 items-center justify-start" style={{ backgroundImage: `url(${heroContent.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="text-white w-100 p-16 bg-blue-800 rounded-md">
        <p className="font-semibold">Miami International Airport</p>
        <h1 className="text-4xl font-bold">MIA Airport</h1>
        <div>
        <CallToAction link="/lost-item-report/?tab=airport" text="File a claim" /> 
        </div>
        
      </div>
    </div>
  );
};

export default HeroLanding;
