import React, { useState, useEffect } from 'react';
import CallToAction from '../cta';
import { getHeroData } from '../utility';

const Hero = () => {
  const [heroContent, setHeroContent] = useState({});

  useEffect(() => {
    const data = getHeroData();
    setHeroContent(data);
  }, []);

  return (
    <div id='top' className="bg-blue-900 h-screen flex flex-col items-center justify-center" style={{ backgroundImage: `url(${heroContent.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="text-center text-white mb-8 w-11/12">
        <p className="text-lg md:text-xl lg:text-2xl font-semibold mb-8">{heroContent.subtitle}</p>
        <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold">{heroContent.title}</h1>
      </div>
      <div className="flex flex-col space-y-4">
        {heroContent.buttons && heroContent.buttons.map((button, index) => (
          <CallToAction key={index} link={button.link} text={button.text} />
        ))}
      </div>
    </div>
  );
};

export default Hero;
