import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Hero from "../../components/landing_pages/hero_landing";
import Ribbon from "../../components/landing_pages/ribbon";
import LeaveBehind from "../../components/landing_pages/leavebehind";
import ServiceAreas from "../../components/landing_pages/serviceareas";
import HowItWorks from "../../components/landing_pages/howitworks";
import TimeNot from "../../components/landing_pages/timenot";
import Marquee from "../../components/landing_pages/marquee";
import ReviewsCarousel from "../../components/landing_pages/reviews";
import About from "../../components/landing_pages/about";
import FaqSection from "../../components/landing_pages/faq";
import PricingTables from "../../components/pricing_tables";
import HeroLanding from "../../components/landing_pages/hero_landing";

function MiaAirport() {
  return (
    <div className="bg-white">
      <Header />
      <HeroLanding />
      <Ribbon />
      <LeaveBehind />
      <ServiceAreas />
      <PricingTables/>
      <HowItWorks />
      <TimeNot />
      <Marquee />
      <ReviewsCarousel />
      <About />
      <FaqSection />
      <Footer />
    </div>
  );
}

export default MiaAirport;
