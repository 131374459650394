import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const TruelyLegit = ({}) => {
  const location = useLocation();

  useEffect(() => {
    // Create script element
    const script = document.createElement("script");
    script.src =
      "https://badge.trulylegit.com/api/tlv1?siteId=03d66d3b-103e-4f3e-92ca-d2836ca23445";
    script.async = true;

    // Insert script into the document
    const entry = document.getElementsByTagName("script")[0];
    entry.parentNode?.insertBefore(script, entry);
    document.getElementById("truly-badge-bottom-left")?.remove();

    //   // Cleanup function to remove the script on unmount
    //   return () => {
    //     entry?.parentNode?.removeChild(script);
    //     const imagesWithAbcSrc = document.querySelectorAll(
    //       'img[src*="https://badge.trulylegit.com"]'
    //     );
    //     imagesWithAbcSrc?.forEach((imgElement) => {
    //       imgElement?.remove();
    //     });
    //   };
  }, [location?.pathname]);
  return (
    <>
      <div id="truly-certified-badge"></div>
    </>
  );
};
export default TruelyLegit;
