import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { getTermsAndPrivacyData } from "../components/utility";
import { Link } from "react-router-dom";

function TemsAndPrivacy() {
  const [termsAndPrivacyContent, setTermsAndPrivacyContent] = useState({});

  useEffect(() => {
    const data = getTermsAndPrivacyData();
    setTermsAndPrivacyContent(data);
  }, []);

  return (
    <div className="md:pt-0 pt-16">
      <Header />
      <div className="bg-blue-900  text-4xl md:text-5xl text-white font-bold py-8">
        {termsAndPrivacyContent.title}
      </div>
      <div className="bg-white">
        <div className="text-left pt-16 pb-8 w-10/12 md:w-8/12 mx-auto bg-white">
          <p className="pb-4 uppercase font-semibold">
            {termsAndPrivacyContent.acceptance}
          </p>
          <div className="w-full text-sm">
            {termsAndPrivacyContent.paragraphs &&
              termsAndPrivacyContent.paragraphs.map((paragraphs, index) => (
                <p className="pb-4">
                  <Link key={index} to={paragraphs.link}>
                    {paragraphs.text}
                  </Link>
                </p>
              ))}
            <div className="py-8">
              <ol
                className="list-inside"
                style={{ listStyle: "none", counterReset: "custom-counter" }}
              >
                {termsAndPrivacyContent.list &&
                  termsAndPrivacyContent.list.map((listItem, index) => (
                    <li key={index}>
                      <h2 className="font-semibold pb-2">
                        {index + 1}. {listItem.item}
                      </h2>
                      <ol className="pb-8 ml-8 list-inside">
                        {listItem.sublist.map((subItem, subIndex) => (
                          <li className="pb-4" key={subIndex}>
                            {index + 1}.{subIndex + 1}.{" "}
                            <p className="ml-8 -mt-6">{subItem.text}</p>
                          </li>
                        ))}
                      </ol>
                    </li>
                  ))}
              </ol>
            </div>
            <p className="pb-4 uppercase font-semibold">
              {termsAndPrivacyContent.privacypolicy}
            </p>
            <p className="pb-8">{termsAndPrivacyContent.privacydesc}</p>
            {termsAndPrivacyContent.privacycontent &&
              termsAndPrivacyContent.privacycontent.map((privacy, index) => (
                <div key={index} className="pb-4">
                  <p className="pb-4 font-semibold">{privacy.title}</p>
                  {privacy.paragraphs.map((paragraph, paraIndex) => (
                    <p key={paraIndex} className="pb-2">
                      {paragraph}
                    </p>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TemsAndPrivacy;
