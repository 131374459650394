import React from "react";
import { Link } from "react-router-dom";

const CallToAction = ({ link, text }) => {
  return (
    <Link
      to={link}
      className="bg-blue-700 hover:bg-blue-800 text-white font-bold py-4 px-8 rounded uppercase text-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-2xl"
    >
      {text}
    </Link>
  );
};

export default CallToAction;
