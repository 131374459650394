// import React, { useEffect, useState } from "react";
// import Header from "../components/header";
// import { useToasts } from "react-toast-notifications";
// import { useQuery } from "urql";
// import { CheckStatusQuery } from "../queries";
// import { Loader, LoadingIcon } from "../icons";
// import Footer from "../components/footer";
// function CheckStatus() {
//   const { addToast } = useToasts();
//   const [clicks, setClicks] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [report, setReport] = useState({ email: "", claim_id: "" });
//   const [status, setStatus] = useState("");
//   const [getStatus, executeQuery] = useQuery({
//     query: CheckStatusQuery,
//     variables: { ...report },
//     pause: true,
//     requestPolicy: "network-only",
//   });

//   const handler = async () => {
//     try {
//       if (
//         report?.claim_id &&
//         report?.email &&
//         report?.email?.trim() !== "" &&
//         report?.claim_id?.trim() !== ""
//       ) {
//         setLoading(true);
//         setClicks(clicks + 1);
//       } else {
//         throw "Both Claim Id and Email are required";
//       }
//     } catch (e) {
//       addToast(`${e}`, { appearance: "error" });
//       return { error: e };
//     }
//   };

//   const updateStatus = async () => {
//     try {
//       if (clicks !== 0) {
//         if (getStatus?.error) {
//           throw getStatus?.error?.message;
//         } else if (getStatus?.data?.reports?.length < 1) {
//           throw "No Matching Report Found. Please Check your Claim Id and Email";
//         } else {
//           setStatus(getStatus?.data?.reports[0]?.status);
//           setReport({ claim_id: "", email: "" });
//         }
//       }
//     } catch (e) {
//       addToast(`${e}`, { appearance: "error" });

//       return { error: e };
//     } finally {
//       setLoading(false);
//     }
//   };
//   useEffect(() => {
//     updateStatus();
//   }, [getStatus?.data, getStatus?.error]);
//   useEffect(() => {
//     if (
//       clicks !== 0 &&
//       report?.claim_id &&
//       report?.email &&
//       report?.email?.trim() !== "" &&
//       report?.claim_id?.trim() !== ""
//     ) {
//       executeQuery({
//         requestPolicy: "network-only",
//       });
//     }
//   }, [clicks]);
//   return (
//     <div className="md:pt-0 pt-16">
//       <Header />
//       <div className="bg-blue-950 text-4xl md:text-5xl text-white font-bold py-8">
//         Claim Status
//       </div>
//       <div className="max-w-3xl mx-auto text-lg pt-6 px-4 md:px-8">
//         <div className="text-white my-4 font-semibold text-center">
//           We're on it!
//         </div>
//         <div className="text-slate-300 my-4 font-medium text-sm text-center mb-10">
//           Thank you for trusting us with your valuable item. We are hard at work
//           trying to track it down. You should have received an email from us
//           shortly after submitting your claim. If you did not get it, check your
//           spam or contact us. We will promptly respond via email.
//         </div>
//       </div>
//       {status?.trim() !== "" ? (
//         <div className="mx-4">
//         <div className="mb-60 bg-white text-center font-semibold max-w-3xl mx-auto p-8 rounded-md">
//           {/*     |  */}
//           {status === "no_payment"
//             ? "You haven't paid yet. Please pay to quicken the process."
//             : status === "open"
//             ? "The claim is open 🙌"
//             : status === "in_process"
//             ? "Claim in Process 🚧"
//             : status === "lf_notified"
//             ? "Lost & Found Notified 🏃"
//             : status === "found"
//             ? "Item Found 😄🎉"
//             : status === "not_found"
//             ? "Item Not Found 😫"
//             : status === "shipped"
//             ? "Item already shipped 📦"
//             : status === "closed"
//             ? "Claim is closed ❌"
//             : status === "not_paid_closed"
//             ? "Claim is closed ❌"
//             : status === "other"
//             ? "Claim is Closed ❌"
//             : "Claim Closed & Refunded 💸↩️❌"}
//         </div>
//         </div>
//       ) : (
//         <div className="p-4 min-h-full">
//         <div className="text-left mb-14 bg-white  max-w-lg mx-auto p-8 rounded-lg pb-8">
//           <div className="text-slate-900 my-4 font-semibold text-center text-2xl pb-4">
//           Track Your Claim's Progress
//         </div>
//         <div className="grid ">
//           <div className="mb-3">
//             <label
//               for="subject-input"
//               className="text-xs text-slate-500 font-semibold"
//             >
//               Email * (Claim-filled Email Address)
//             </label>
//             <input
//               onChange={(e) => {
//                 // setReport({ ...report, email: e.target.value });
//                 const lowercasedEmail = e.target.value.toLowerCase();
//                 setReport({ ...report, email: lowercasedEmail });
//               }}
//               placeholder="Email"
//               type="text"
//               id="subject-input"
//               className={`appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12 w-full lowercase`}
//             />
//           </div>

//           <div className="mb-3">
//             <label
//               for="subject-input"
//               className="text-xs text-slate-500 font-semibold"
//             >
//               Claim Number * (Received via email upon submission)
//             </label>
//             <input
//               onChange={(e) => {
//                 setReport({ ...report, claim_id: e.target.value });
//               }}
//               placeholder="Claim number"
//               type="text"
//               id="subject-input"
//               className={`appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12 w-full lowercase`}
//             />
//           </div>

          
//         </div>
//         <div
//             onClick={(e) => {
//               e.preventDefault();
//               handler();
//             }}
//             className="disabled:cursor-not-allowed cursor-pointer disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full mt-4"
//           >
//             Check <span className="md:block hidden">&nbsp;Claim&nbsp;</span> Status
//             {loading ? <LoadingIcon className="h-8 rounded-full text-blue-200 w-8" /> : null}
//           </div>
//         </div>
//         </div>
//       )}
//       <Footer />
//     </div>
//   );
// }

// export default CheckStatus;

import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { useToasts } from "react-toast-notifications";
import { useQuery } from "urql";
import { CheckStatusQuery } from "../queries";
import { Loader, LoadingIcon } from "../icons";
import Footer from "../components/footer";

function CheckStatus() {
  const { addToast } = useToasts();
  const [clicks, setClicks] = useState(0);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState({ email: "", claim_id: "" });
  const [status, setStatus] = useState("");
  const [emailLogs, setEmailLogs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null); // Track active accordion index
  const [getStatus, executeQuery] = useQuery({
    query: CheckStatusQuery,
    variables: { ...report },
    pause: true,
    requestPolicy: "network-only",
  });

  const handler = async () => {
    try {
      if (
        report?.claim_id &&
        report?.email &&
        report?.email?.trim() !== "" &&
        report?.claim_id?.trim() !== ""
      ) {
        setLoading(true);
        setClicks(clicks + 1);
      } else {
        throw "Both Claim Id and Email are required";
      }
    } catch (e) {
      addToast(`${e}`, { appearance: "error" });
      return { error: e };
    }
  };

  const updateStatus = async () => {
    try {
      if (clicks !== 0) {
        if (getStatus?.error) {
          throw getStatus?.error?.message;
        } else if (getStatus?.data?.reports?.length < 1) {
          throw "No Matching Report Found. Please Check your Claim Id and Email";
        } else {
          const reportData = getStatus?.data?.reports[0];
          setStatus(reportData?.status);
          
          // Set first_name, claim_id, and email in the report state
          setReport({ 
            claim_id: reportData?.claim_id,
            email: reportData?.email,
            first_name: reportData?.first_name
          });
          
          // Sort email logs by date in descending order
          const sortedLogs = (reportData?.email_logs || []).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
          setEmailLogs(sortedLogs);
        }
      }
    } catch (e) {
      addToast(`${e}`, { appearance: "error" });
      return { error: e };
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    updateStatus();
  }, [getStatus?.data, getStatus?.error]);

  useEffect(() => {
    if (
      clicks !== 0 &&
      report?.claim_id &&
      report?.email &&
      report?.email?.trim() !== "" &&
      report?.claim_id?.trim() !== ""
    ) {
      executeQuery({
        requestPolicy: "network-only",
      });
    }
  }, [clicks]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="md:pt-0 pt-16">
      <Header />
      <div className="bg-blue-950 text-4xl md:text-5xl text-white font-bold py-8">
        Claim Status
      </div>
      <div className="max-w-3xl mx-auto text-lg pt-6 px-4 md:px-8">
        
        
      </div>
      {status?.trim() !== "" ? (
        <div className="mx-4">
          <div className="mb-20 pb-10 bg-white text-center max-w-3xl mx-auto rounded-xl">
            <div className="text-xl font-bold bg-green-500 w-100 rounded-t-xl text-white pt-6 pb-5">
            {status === "no_payment"
              ? "You haven't paid yet. Please pay to quicken the process."
              : status === "open"
              ? "The claim is open 🙌"
              : status === "in_process"
              ? "Claim in Process 🚧"
              : status === "lf_notified"
              ? "Lost & Found Notified 🏃"
              : status === "found"
              ? "Item Found 😄🎉"
              : status === "not_found"
              ? "Item Not Found 😫"
              : status === "shipped"
              ? "Item already shipped 📦"
              : status === "closed"
              ? "Claim is closed ❌"
              : status === "not_paid_closed"
              ? "Claim is closed ❌"
              : status === "other"
              ? "Claim is Closed ❌"
              : "Claim Closed & Refunded 💸↩️❌"}
</div>
        <div className="text-slate-900 text-center md:mx-10 mx-4 mt-10">
          <span className="font-bold">Claim ID: {report.claim_id} <br/>Email: {report.email}</span>
        </div>
      <div className="text-slate-900 my-4 font-medium text-sm text-center mb-4 md:mx-20 mx-4">
          Thank you for trusting us with your valuable item. We are hard at work
          trying to track it down. You should have received an email from us
          shortly after submitting your claim. If you did not get it, check your
          spam or contact us. We will promptly respond via email.
        </div>
        <div className="md:px-10 px-4">
{/*        
<ol className="relative border-l border-slate-300">
  {emailLogs.map((log, index) => (
    <li className=" ml-4" key={index}>
      <div
        className={`absolute w-3 h-3 rounded-full mt-8 -left-1.5 border border-white ${
          index === 0 ? 'bg-blue-800 animate-ping' : 'bg-slate-300'
        }`}
      ></div>
      <button
        onClick={() => toggleAccordion(index)}
        className={`w-full text-left p-4 focus:outline-none border border-slate-300 mt-2 ${
          index === 0 ? 'bg-blue-800 text-white' : 'bg-slate-200'
        }`}
      >
        <div className="flex items-center justify-between">
          <span className={`font-semibold ${index === 0 ? 'text-white' : ''}`}>{log.subject}</span>
          <div className="flex gap-6">
            <span className="font-semibold">{new Date(log.created_at).toLocaleDateString()}</span>
            <svg
              className={`w-6 h-6 transition-transform transform ${
                activeIndex === index ? 'rotate-180' : ''
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </div>
        </div>
      </button>
      {activeIndex === index && (
        <div
          className={`px-6 py-4 bg-slate-100 font-medium text-sm text-slate-800 text-left border border-slate-300 ${
            index === 0 ? 'bg-blue-700' : 'bg-slate-100 text-slate-800'
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: log.body }} />
        </div>
      )}
    </li>
  ))}
</ol> */}

<ol className="relative border-l border-slate-300">
  {emailLogs.map((log, index) => {
    // Clean the subject by removing ' - Lost and Found Concierge'
    const cleanSubject = log.subject.replace(' - Lost and Found Concierge', '');

    return (
      <li className="ml-4" key={index}>
        <div
          className={`absolute w-3 h-3 rounded-full mt-8 -left-1.5 border border-white ${
            index === 0 ? 'bg-blue-800 animate-ping' : 'bg-slate-300'
          }`}
        ></div>
        <button
          onClick={() => toggleAccordion(index)}
          className={`w-full text-left p-4 focus:outline-none border border-slate-300 mt-2 ${
            index === 0 ? 'bg-blue-800 text-white' : 'bg-slate-200'
          }`}
        >
          <div className="flex items-center justify-between">
            <span className={`font-semibold ${index === 0 ? 'text-white' : ''}`}>
              {cleanSubject}
            </span>
            <div className="flex gap-6">
              <span className="font-semibold">{new Date(log.created_at).toLocaleDateString()}</span>
              <svg
                className={`w-6 h-6 transition-transform transform ${
                  activeIndex === index ? 'rotate-180' : ''
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
        </button>
        {activeIndex === index && (
          <div
            className={`px-6 py-4 bg-slate-100 font-medium text-sm text-slate-800 text-left border border-slate-300 ${
              index === 0 ? 'bg-blue-700' : 'bg-slate-100 text-slate-800'
            }`}
          >
            <div dangerouslySetInnerHTML={{ __html: log.body }} />
          </div>
        )}
      </li>
    );
  })}
</ol>

</div>
          </div>

          
        </div>
      ) : (
        <div className="p-4 min-h-full">
          <div className="text-left mb-14 bg-white max-w-lg mx-auto p-8 rounded-lg pb-8">
            <div className="text-slate-900 my-4 font-semibold text-center text-2xl pb-4">
              Track Your Claim's Progress
            </div>
            <div className="grid ">
              <div className="mb-3">
                <label
                  htmlFor="email-input"
                  className="text-xs text-slate-500 font-semibold"
                >
                  Email * (Claim-filled Email Address)
                </label>
                <input
                  onChange={(e) => {
                    const lowercasedEmail = e.target.value.toLowerCase();
                    setReport({ ...report, email: lowercasedEmail });
                  }}
                  placeholder="Email"
                  type="text"
                  id="email-input"
                  className="appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12 w-full lowercase"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="claim-input"
                  className="text-xs text-slate-500 font-semibold"
                >
                  Claim Number * (Received via email upon submission)
                </label>
                <input
                  onChange={(e) => {
                    setReport({ ...report, claim_id: e.target.value });
                  }}
                  placeholder="Claim number"
                  type="text"
                  id="claim-input"
                  className="appearance-none font-semibold cursor-text bg-slate-50 rounded-lg px-2 my-1 border-2 border-slate-200 text-slate-700 text-sm h-12 w-full lowercase"
                />
              </div>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                handler();
              }}
              className="disabled:cursor-not-allowed cursor-pointer disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full mt-4"
            >
              Check <span className="md:block hidden">&nbsp;Claim&nbsp;</span> Status
              {loading ? <LoadingIcon className="h-8 rounded-full text-blue-200 w-8" /> : null}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default CheckStatus;


