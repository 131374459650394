import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Logo from "./logo";
import CallToActionHeader from './cta_header';
import CallToAction from './cta';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-white fixed w-full md:sticky top-0 z-20">
      <nav className="mx-auto flex max-w-8xl items-center justify-between" aria-label="Global">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Lost and Found Concierge</span>
            <div className="bg-blue-800 px-4 py-4 transition-all"><Logo /></div>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-slate-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 mr-4" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link to="/" className="text-sm font-semibold leading-6 text-slate-900 cursor-pointer" smooth={true}
                duration={500}
                offset={-50}>
            Home
          </Link>
          <Link to="/#about" className="text-sm font-semibold leading-6 text-slate-900 cursor-pointer" smooth={true}
                duration={500}
                offset={-50}>
            About
          </Link>
          <Link to="/#services" className="text-sm font-semibold leading-6 text-slate-900 cursor-pointer" smooth={true}
                duration={500}
                offset={-50}>
            Services
          </Link>
          <Link to="/#faq" className="text-sm font-semibold leading-6 text-slate-900 cursor-pointer" smooth={true}
                duration={500}
                offset={-50}>
            FAQ
          </Link>
          <Link to="/check-status" className="text-sm font-semibold leading-6 text-slate-900 cursor-pointer">
            Check Status
          </Link>
          <Link to="/contact-us" className="text-sm font-semibold leading-6 text-slate-900 cursor-pointer">
            Contact
          </Link>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end mr-2">
        {/* <CallToActionHeader link="file-a-claim" text="File a Claim" /> */}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="z-20" />
        <Dialog.Panel className="fixed top-0 w-full h-full right-0 z-20 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-slate-900/10">
          <div className="flex items-center justify-between">
            <a to="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Lost and Found Concierge</span>
              <div className="bg-blue-800 px-4 py-4"><Logo /></div>
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-slate-700 mr-2"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="flow-root">
            <div className="">
              <div className="space-y-2">
                <Link to="/#top" className="px-8 py-4 block text-base font-semibold leading-7 text-slate-900 hover:bg-blue-800 hover:text-white cursor-pointer" smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setMobileMenuOpen(false)} >
                Home
                </Link>
                <Link to="/#about" className="px-8 py-4 block text-base font-semibold leading-7 text-slate-900 hover:bg-blue-800 hover:text-white cursor-pointer" smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setMobileMenuOpen(false)} >
                About
                </Link>
                <Link to="/#services" className="px-8 py-4 block text-base font-semibold leading-7 text-slate-900 hover:bg-blue-800 hover:text-white cursor-pointer" smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setMobileMenuOpen(false)} >
                Services
                </Link>
                <Link to="/#faq" className="px-8 py-4 block text-base font-semibold leading-7 text-slate-900 hover:bg-blue-800 hover:text-white cursor-pointer" smooth={true}
                duration={500}
                offset={-50}
                onClick={() => setMobileMenuOpen(false)} >
                FAQ
                </Link>
                <Link
                  to="/check-status"
                  className="px-8 py-4 block text-base font-semibold leading-7 text-slate-900 hover:bg-blue-800 hover:text-white"
                  onClick={() => setMobileMenuOpen(false)} 
                >
                  Check Status
                </Link>
                <Link
                  to="/contact-us"
                  className="px-8 py-4 block text-base font-semibold leading-7 text-slate-900 hover:bg-blue-800 hover:text-white"
                  onClick={() => setMobileMenuOpen(false)} 
                >
                  Contact
                </Link>
              </div>
              <div className='text-center mt-12 py-2'>
              {/* <CallToAction link="/file-a-claim" text="File a Claim"
              onClick={() => setMobileMenuOpen(false)}  /> */}
              </div>
              
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
