import React, { useState, useEffect } from "react";
import { getAboutData } from "../utility";
import { Link } from "react-router-dom";

const About = () => {
  const [aboutContent, setAboutContent] = useState({});

  useEffect(() => {
    const data = getAboutData();
    setAboutContent(data);
  }, []);

  return (
    <div id="about" className="text-left pt-16 pb-8 w-10/12 md:w-8/12 mx-auto">
      <h2 className="text-3xl font-bold text-blue-700 mb-8 uppercase">
        {aboutContent.title}
      </h2>
      <div className="flex flex-wrap justify-between w-full mx-auto">
        <div className="w-full">
          {aboutContent.paragraphs &&
            aboutContent.paragraphs.map((paragraphs, index) => (
              <p className="pb-4 font-semibold text-slate-700">
                <Link key={index} to={paragraphs.link}>
                  {paragraphs.text}
                </Link>
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default About;
