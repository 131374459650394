import React from 'react';
import { Link } from 'react-router-dom';

const PayPalLogo = () => {
  const openPayPalPopup = () => {
    window.open('https://www.paypal.com/', 'WIPaypal', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700');
  };

  return (
    <table border="0" cellPadding="10" cellSpacing="0" align="center">
      <tbody>
        <tr>
          <td align="center"></td>
        </tr>
        <tr>
          <td align="center">
            <Link target='_blank' to="https://www.paypal.com/" title="How PayPal Works" onClick={openPayPalPopup}>
              <img src="https://www.paypalobjects.com/webstatic/mktg/logo/bdg_secured_by_pp_2line.png" border="0" alt="Secured by PayPal" />
            </Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PayPalLogo;
