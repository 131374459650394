import React, { useEffect, useState } from "react";
import {
  Airport,
  CheckMark,
  Hotel,
  RentalCar,
  Ship,
  ThemePark,
  Vegas,
} from "../icons";
import Location from "../components/location_date/location_and_date";
import PersonalDetails from "../components/personal_details";
import ItemDetails from "../components/item_details";
import { useMutation } from "urql";
import { FileReportMutation } from "../queries";
import { ToastProvider, useToasts } from "react-toast-notifications";
import HeaderPayment from "../components/header_payment";
import FooterPayment from "../components/footer_payment";
import { useSearchParams, Link } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { hasuraEvents } from "../utils/hasura_events";

const tabsHeader = [
  { icon: Airport, title: "Airport / Airline", id: 0 },
  { icon: RentalCar, title: "Rental Car", id: 1 },
  { icon: Hotel, title: "Hotel / AirBnB", id: 2 },
  { icon: Vegas, title: "Las Vegas", id: 3 },
  { icon: ThemePark, title: "Theme Park", id: 4 },
  { icon: Ship, title: "Cruise Ship", id: 5 },
];
const stepperItems = [
  { count: 1, label: "Location & Date", mobileLabel: "Where/ When" },
  { count: 2, label: "Item Details", mobileLabel: "What" },
  { count: 3, label: "Personal Details", mobileLabel: "Who" },
];

function Claim() {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedTab, setSelectedTab] = useState();
  const [selectedStepper, setSelectedStepper] = useState(stepperItems[0]);
  const [schema, setSchema] = useState([]);
  const [images, setImages] = useState([]);
  //[[{},{},{}],[{}],[]]
  const [progress, setProgress] = useState([]);
  //  [[{progress:},{progress:}],[],[],[]]
  const reportInit = {
    report_categories: { data: [] },
    lost_time_of_the_day: "",
    address: "",
    city: "",
    country: "",
    email: "",
    first_name: "",
    last_name: "",
    location: selectedTab?.title || tabsHeader[0]?.title,
    location_details: [
      {
        title: "lost place",
        value: ["at airport"],
      },
    ],
    lost_date: new Date().toUTCString(),
    phone_number: "",
    zip: "",
  };
  const activeStepInit = {
    0: { valid: false, navigate: true },
    1: { valid: false, navigate: false },
    2: { valid: false, navigate: false },
  };
  const [report, setReport] = useState({});
  const [activeStep, setActiveStep] = useState({});
  const [reportFile, reportFileMutation] = useMutation(FileReportMutation);

  const handleSetReport = async (data) => {
    setReport({ ...report, ...data });
  };

  const clearReport = async () => {
    setReport({
      ...reportInit,
    });
    setActiveStep(activeStepInit);
    setSelectedStepper(stepperItems[0]);
    setSelectedTab(tabsHeader[0]);
    localStorage.removeItem("reportDetails");
    localStorage.removeItem("reportId");
  };

  const fileReport = async (config) => {
    try {
      setLoading(true);
      // if (report?.item_category === "Other") {
      //   delete report?.item_category;
      // }

      const { item_category, ...rest } = report;
      const file = await reportFileMutation({
        objects: report?.item_category === "Other" ? { ...rest } : report,
      });
      if (file?.data) {
        console.log(file?.data, "insert");
        setLoading(false);
        // create the schedular for payment
        await hasuraEvents(
          "payment_failure",
          { report_id: file?.data?.insert_reports_one?.id },
          new Date(
            new Date().setMinutes(
              new Date().getMinutes() +
                parseInt(config?.payment_reminder?.time) || 30
            )
          ).toISOString()
        );
        await hasuraEvents(
          "payment_failure",
          { report_id: file?.data?.insert_reports_one?.id },
          new Date(
            new Date().setMinutes(
              new Date().getMinutes() +
                parseInt(config?.payment_reminder?.second_attempt) || 120
            )
          ).toISOString()
        );
        return {
          status: "ok",
          id: file?.data?.insert_reports_one?.id,
        };
      } else {
        throw reportFile?.error;
      }
    } catch (e) {
      console.log(e, "error");
      addToast(`${e ? e : ""} Report couldn't be filed`, {
        appearance: "error",
      });
      setLoading(false);
      return { error: e };
    }
  };

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const reportDetails = JSON.parse(localStorage.getItem("reportDetails"));
    if (
      reportDetails?.report &&
      Object.keys(reportDetails?.report).length > 0
    ) {
      setReport(reportDetails?.report);
    } else {
      setReport({ ...reportInit });
    }
    setImages(reportDetails?.images);
    setProgress(reportDetails?.progress);
    setSelectedStepper(reportDetails?.selectedStepper || stepperItems[0]);
    setSelectedTab(
      searchParams.get("tab") === "airport" ||
        searchParams.get("tab") === "airline"
        ? tabsHeader[0]
        : searchParams.get("tab") === "rentalcar"
        ? tabsHeader[1]
        : searchParams.get("tab") === "hotel" ||
          searchParams.get("tab") === "airbnb"
        ? tabsHeader[2]
        : searchParams.get("tab") === "vegas"
        ? tabsHeader[3]
        : searchParams.get("tab") === "themepark"
        ? tabsHeader[4]
        : searchParams.get("tab") === "cruiseship"
        ? tabsHeader[5]
        : tabsHeader[reportDetails?.selectedTab?.id] || tabsHeader[0]
    );
    if (
      reportDetails?.activeStep &&
      Object.keys(reportDetails?.activeStep).length > 0
    ) {
      setActiveStep(reportDetails?.activeStep);
    } else {
      setActiveStep(activeStepInit);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(report).length > 0) {
      localStorage.setItem(
        "reportDetails",
        JSON.stringify({
          selectedStepper,
          selectedTab,
          report,
          activeStep,
          images,
          progress,
        })
      );
    }
  }, [report, selectedStepper, selectedTab, activeStep, images, progress]);
  // useEffect(() => {
  //   console.log(report);
  // }, [report]);
  // ip location
  useEffect(() => {
    if (Object.keys(report).length > 0 && !report?.ip_address) {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          setReport({
            ...report,
            ip_address: data?.ip,
            ip_location: `${data?.city}, ${data?.country_name}`,
          });
        })
        .catch((error) => console.log(error));
    }
  }, [report]);
  return (
    <>
      {" "}
      <HeaderPayment />
      <form className="">
        <div className="pb-0 md:pb-4 bg-white max-w-5xl mx-2 md:mx-auto md:my-4 my-2 rounded-md md:min-h-fit min-h-screen">
          {/* tabs with icon */}
          {searchParams.get("success") === "false" ||
          searchParams.get("success") === "true" ? (
            <div className="pb-8"></div>
          ) : (
            <div className="tabs-with-icons bg-white grid grid-cols-6">
              {tabsHeader?.map((tab, i) => (
                <div
                  key={i}
                  className={`${
                    selectedTab === tab
                      ? " text-slate-900 font-bold"
                      : "text-slate-300 bg-slate-100 font-bold"
                  } pb-4 cursor-pointer`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (selectedStepper?.count === 1) {
                      setReport({
                        ...report,
                        location: tab?.title,
                        location_details:
                          tab.title === tabsHeader[0].title
                            ? [
                                {
                                  title: "lost place",
                                  value: ["at airport"],
                                },
                              ]
                            : [],
                      });
                      setSelectedTab(tab);
                      setErrors({});
                    }
                  }}
                >
                  <div className="flex justify-center">
                    {" "}
                    <tab.icon
                      className={`w-12 h-12 mt-4 md:w-12 md:h-12 md:mt-8 `}
                      aria-hidden="true"
                    />
                  </div>
                  <p
                    className={`block text-[10px] w-12 mx-auto md:w-full md:text-sm  my-1  `}
                  >
                    {tab?.title}
                  </p>
                </div>
              ))}
            </div>
          )}
          {searchParams.get("success") === "true" ? (
            <div className="my-24"></div>
          ) : searchParams.get("success") === "false" ? (
            <div className="my-16">
              <div className="relative p-4 w-full max-w-lg h-full md:h-auto m-auto">
                <div className="relative p-4 text-center rounded-md shadow sm:p-5 border-2 border-red-100">
                  <div className="w-12 h-12 rounded-full bg-red-200 flex items-center justify-center mx-auto mb-3.5">
                    <RxCross2 color="red" size={30} />
                  </div>
                  <p className="mb-4 font-bold text-slate-900 text-xl">
                    Something went wrong
                  </p>
                  <p className="font-semibold text-sm text-slate-700 mb-4">
                    No worries! Your report is all set. You'll receive a payment
                    request email within the next hour. <br />
                    If you want things to move faster, go ahead and contact our
                    support team.
                  </p>
                  <Link to="mailto:support@lostandfoundconcierge.com">
                    <button
                      type="button"
                      className="disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-blue-700 rounded-md py-3 px-8 bg-blue-700 text-white font-bold text-lg flex justify-center hover:bg-blue-800 hover:shadow-md w-full"
                    >
                      Contact via Email
                    </button>
                  </Link>
                  <p className="mt-4 text-sm font-semibold text-slate-600">
                    Sincerely,
                  </p>
                  <p className="mb-4 text-sm  font-semibold text-slate-600">
                    Customer Care Team
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-b-md px-4 md:px-10 pb-2">
              {/* stepper */}
              <div className="max-w-4xl mx-auto">
                <div className="pt-6 pb-4">
                  <div className="flex items-center font-bold">
                    {stepperItems?.map((item, i) => {
                      return (
                        <>
                          <div
                            onClick={(e) => {
                              e.preventDefault();

                              if (
                                activeStep[i]?.navigate
                                // && check
                              ) {
                                setSelectedStepper(item);
                              }
                            }}
                            key={i}
                            className={`flex items-center ${
                              selectedStepper?.count === item?.count
                                ? "text-white"
                                : selectedStepper?.count > i + 1
                                ? "text-slate-900"
                                : "text-slate-200 "
                            } ${
                              activeStep[i]?.valid || activeStep[i]?.navigate
                                ? "cursor-pointer"
                                : "cursor-not-allowed"
                            } relative 
                            `}
                            // ${check && "opacity-30 cursor-default"}
                          >
                            <div
                              className={`rounded-full transition duration-500 ease-in-out h-9 w-9 pt-1 border-2 ${
                                selectedStepper?.count === parseInt(i) + 1
                                  ? "bg-blue-700 border-slate-100 text-white shadow-xl"
                                  : selectedStepper?.count > i + 1
                                  ? "bg-white border-blue-700 text-blue-700"
                                  : "border-slate-100"
                              } `}
                            >
                              {activeStep[i]?.valid ? (
                                <CheckMark className="text-xs md:text-sm w-6 h-6 ml-1" />
                              ) : (
                                <div className="text-base">{item?.count}</div>
                              )}
                            </div>
                            <div
                              className={`${
                                selectedStepper?.count === parseInt(i) + 1
                                  ? "text-slate-900"
                                  : "text-slate-300"
                              } absolute mt-20 text-sm w-32  md:text-left select-none md:${
                                i === 0
                                  ? ""
                                  : i === 1
                                  ? "-mr-12"
                                  : i === 2
                                  ? "-ml-20"
                                  : ""
                              }`}
                              style={{
                                marginLeft: i === 2 ? "-84px" : "",
                              }}
                            >
                              <div className="hidden md:block">
                                {item?.label}
                              </div>{" "}
                              <div
                                className={`md:hidden ${
                                  i === 0
                                    ? "text-left"
                                    : i === 1
                                    ? "text-left"
                                    : i === 2
                                    ? "mr-2 text-right"
                                    : ""
                                }`}
                              >
                                {item?.mobileLabel}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`${i === 2 && "hidden"} ${
                              selectedStepper?.count - 1 >= i + 1
                                ? "border-blue-700 shadow-xl"
                                : "border-slate-100"
                            } flex-auto border-t-2 transition duration-500 ease-in-out `}
                          ></div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              {selectedStepper?.count === 1 && (
                <Location
                  lostTime={report?.lost_time_of_the_day}
                  selectedStepper={selectedStepper}
                  selectedTab={selectedTab}
                  setSelectedStepper={setSelectedStepper}
                  stepperItems={stepperItems}
                  locationDetails={report?.location_details || []}
                  handleSetReport={handleSetReport}
                  lostDate={report?.lost_date}
                  itemDescription={report?.item_description}
                  itemCategory={report?.item_category}
                  loading={loading}
                  lostTimeDescription={report?.lost_time_description}
                  errors={errors}
                  setErrors={setErrors}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                />
              )}
              {selectedStepper?.count === 2 && (
                <ItemDetails
                  itemDescription={report?.item_description}
                  itemDetails={report?.lost_item_details}
                  selectedStepper={selectedStepper}
                  selectedTab={selectedTab}
                  setSelectedStepper={setSelectedStepper}
                  stepperItems={stepperItems}
                  itemCategory={report?.report_categories}
                  handleSetReport={handleSetReport}
                  lostItemDetails={report?.lost_item_details}
                  loading={loading}
                  errors={errors}
                  setErrors={setErrors}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  schema={schema}
                  setSchema={setSchema}
                  images={images}
                  setImages={setImages}
                  report={report}
                  progress={progress}
                  setProgress={setProgress}
                />
              )}

              {selectedStepper?.count === 3 && (
                <PersonalDetails
                  selectedStepper={selectedStepper}
                  selectedTab={selectedTab}
                  setSelectedStepper={setSelectedStepper}
                  stepperItems={stepperItems}
                  details={report}
                  handleSetReport={handleSetReport}
                  fileReport={fileReport}
                  loading={loading}
                  clearReport={clearReport}
                  errors={errors}
                  setErrors={setErrors}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  schema={schema}
                  setSchema={setSchema}
                  // check={check}
                  // setCheck={setCheck}
                />
              )}
            </div>
          )}
        </div>
      </form>
      <div className="md:h-16 h-0"></div>
      <FooterPayment />
    </>
  );
}

export default Claim;