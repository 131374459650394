import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { AiFillCheckCircle } from 'react-icons/ai';
import CallToAction from "../components/cta";
import { getFileAClaimData } from '../components/utility';
import { useQuery } from "urql";
import { useSearchParams } from "react-router-dom";
import { GetReportDataQuery } from "../queries";
import PricingTables from "../components/pricing_tables";




function FileAClaim() {
  const [fileAClaimContent, setFileAClaimContent] = useState({});
  const [param] = useSearchParams();
  const [configResults, reexecuteConfigQuery] = useQuery({
    query: GetReportDataQuery,
    pause: true,
  });
  
  const {
    data: configsData,
    fetching: configsFetching,
    error: configsError,
  } = configResults;

  useEffect(() => {
    reexecuteConfigQuery({ requestPolicy: "network-only" });
  }, []);

  useEffect(() => {
    const data = getFileAClaimData();
    setFileAClaimContent(data);
  }, []);

  
  return (
    <div className="md:pt-0 pt-16">
      <Header />
      <div className="bg-blue-900  text-4xl md:text-5xl text-white font-bold p-8">
      {fileAClaimContent.title}
      </div>
      <div className="bg-white">
      <div className="text-center pt-16 pb-8 w-10/12 md:w-9/12 mx-auto">
      <h2 className="text-3xl font-bold text-blue-800 mb-8">{fileAClaimContent.mainHeading}</h2>
      <p className="font-semibold text-slate-800">{fileAClaimContent.mainContent}</p>
   <div className="border-t border-blue-800 b-2 w-auto mx-auto my-8"></div>
   {fileAClaimContent.subContent && (
          <div className="text-left py-8 space-y-8 font-semibold text-slate-800">
            {fileAClaimContent.subContent.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
              
            ))}
          </div>
        )}
      <h2 className="text-3xl font-bold text-blue-800 pt-8 mb-8 uppercase">{fileAClaimContent.thingsToKnowHeading}</h2>
      <div className="mb-12">
      <ul className="list-inside md:list-disc space-y-4">
        {fileAClaimContent.itemsToKnow &&
          fileAClaimContent.itemsToKnow.map((item, index) => (
        <li key={index} className="md:flex items-center space-x-2 pb-12">
          <div className="w-16 md:mx-0 mx-auto md:pb-0 pb-4">
            <AiFillCheckCircle className="text-blue-800 mr-1 mt-0" size={48} />
          </div>
          <div className="text-left">
            <span className="text-xl font-bold text-slate-800">{item.title}</span>
            <p className="font-semibold text-slate-600 text-justify">{item.content}</p>
          </div>
        </li>
      ))}
      <li className="md:flex items-center space-x-2">
    <div className="w-16 md:mx-0 mx-auto md:pb-0 pb-4">
      <AiFillCheckCircle className="text-blue-800 mr-1 mt-0" size={48} />
    </div>
    <div className="text-left">
      <span className="text-xl font-bold  text-slate-800">Do I get a refund if I change my mind?</span>
      <p className="font-semibold text-slate-600 text-justify">Maybe you found your item (congrats! 🥳) or just decided LFC is not for you 😢. No hard feelings! We’re happy to issue a full “No Questions Asked” refund within the first 24 hours of submitting your claim. Simply reply to the Welcome Email or support@lostandfoundconcierge.com.</p>
    </div>
  </li>
      </ul>
      </div>
      </div>
      <PricingTables/>
    <h2 className="text-3xl font-bold text-blue-700 mt-16 mb-4">{fileAClaimContent.proceedHeading}</h2>
    <p className="font-semibold text-slate-800">{fileAClaimContent.proceedContent}</p>
    <div className="sticky bottom-8  mt-4 z-10">
    <div className="flex space-y-4 px-8 justify-center">
    {fileAClaimContent.buttons &&
            fileAClaimContent.buttons.map((button, index) => (
              <CallToAction key={index} link={button.link} text={button.text} />
            ))}
            </div>
            </div>
            <div className="sticky bottom-0 bg-gradient-to-t from-white h-16 -z-0"></div>
            </div>
      <Footer />
    </div>
  );
}

export default FileAClaim;
