import CookieConsent from "react-cookie-consent";
const CookieBanner = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="lost-found-report"
      style={{ background: "#FFFFFF", color:"#334155", fontSize:"14px", fontWeight:"semibold", boxShadow:"initial"}}
      buttonStyle={{ background:"#1d4ed8", color: "#FFFFFF", fontSize: "16px", fontWeight: "bold", textTransform: "uppercase", borderRadius: "2px", padding: "10px 20px"}}
      expires={150}
    >
      <span style={{ fontSize: "18px" }}>🍪</span> <span className="font-semibold">This website uses cookies for a better user experience.</span>
    </CookieConsent>
  );
};
export default CookieBanner;
