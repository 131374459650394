import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DBProvider } from "./context/database_context";
import Landing from "./pages/landing";
import FileAClaim from "./pages/file-a-claim";
import Claim from "./pages/claim";
import CheckStatus from "./pages/check-status";
import Payment from "./pages/payment";
import Contact from "./pages/contact";
import TemsAndPrivacy from "./pages/terms-and-privacy";
import PaymentTerms from "./pages/payment-terms";
import MiaAirport from "./pages/airports/mia-airport";
import { ToastProvider, useToasts } from "react-toast-notifications";

import { ThemeProvider } from "./context/theme_context";
import ClaimSuccess from "./pages/claim-success";
import CookieBanner from "./components/cookie_bar";
// const initialOptions = {
//   clientId:
//     ,
//   currency: "USD",
//   intent: "capture",
// };
function App() {
  return (
    <ThemeProvider>
      <DBProvider>
        <ToastProvider>
          <div className="App scroll-smooth	bg-blue-800">
            <Router>
              <Routes>
                {/* keep this for now */}
                <Route path={"/landing"} element={<Landing />} />
                {/* landing will become main page when we change dns */}
                <Route path={"/"} element={<Landing />} />

                {/* just keep this for now as well - until we change dns */}
                {/* <Route path={"/"} element={<Claim />} /> */}
                <Route path={`/:id`} element={<ClaimSuccess />} />
                {/* claim will become /lost-item-report when we change dns */}
                <Route path={"/lost-item-report"} element={<Claim />} />

                <Route path={"/check-status"} element={<CheckStatus />} />
                <Route path={"/payment"} element={<Payment />} />

                {/* Newly created pages no route change needed */}
                <Route path={"/file-a-claim"} element={<FileAClaim />} />
                <Route path={"/contact-us"} element={<Contact />} />
                <Route
                  path={"/terms-and-privacy"}
                  element={<TemsAndPrivacy />}
                />
                <Route path={"/payment-terms"} element={<PaymentTerms />} />

                {/* Airport lading pages */}
                <Route path={"/mia-airport"} element={<MiaAirport />} />
              </Routes>
            </Router>
            <CookieBanner />
          </div>
        </ToastProvider>
      </DBProvider>
    </ThemeProvider>
  );
}

export default App;
